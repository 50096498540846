import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    meta: { title: '首页', keepAlive: true },
    redirect: { path: '/IndexOne' },
    component: () => import('@/views/Index.vue'),
    children: [
      {
        path: '/IndexOne',
        name: 'IndexOne',
        meta: { title: '首页1', keepAlive: true },
        component: () => import('@/views/indexOne.vue'),
      },
      {
        path: '/IndexTwo',
        name: 'IndexTwo',
        meta: { title: '首页2', keepAlive: true },
        component: () => import('@/views/indexTwo.vue'),
      }, {
        path: '/Product',
        name: 'Product',
        meta: { title: '产品与解决方案', keepAlive: true },
        component: () => import('@/views/Product.vue'),
      }, {
        path: '/Service',
        name: 'Service',
        meta: { title: '服务案例', keepAlive: true },
        component: () => import('@/views/Service.vue'),
      },
      {
        path: '/Contract',
        name: 'Contract',
        meta: { title: '联系我们', keepAlive: true },
        component: () => import('@/views/Contract.vue'),
      },
      {
        path: '/DetailPage',
        name: 'DetailPage',
        meta: { title: '详情页' },
        component: () => import('@/views/DetailPage.vue'),
        children: [
          {
            path: '/EmptyViewW',
            name: 'EmptyViewW',
            //为暂无数据的
            meta: { title: '', keepAlive: false },
            component: () => import('@/views/emptyView.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/StaticMap',
    name: 'StaticMap',
    meta: { title: '地图' },
    component: () => import('@/views/StaticMap.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('@/views/back/Login.vue')
  },
  {
    path: '/SubLogin',
    name: 'SubLogin',
    meta: { title: '单点登录' },
    component: () => import('@/views/SubLogin.vue')
  },
  {
    path: '/SecLogin',
    name: 'SecLogin',
    meta: { title: 'sec单点登录' },
    component: () => import('@/views/SecLogin.vue')
  },
  {
    path: '/open/OpenPage',
    name: 'OpenPage',
    meta: { title: '开放页面' },
    component: () => import('@/views/open/OpenPage.vue')
  },

  {
    path: '/BackIndex',
    name: 'BackIndex',
    meta: { title: '首页' },
    redirect: { path: '/Development' },
    component: () => import('@/views/back/Index.vue'),
    children: [
      {
        path: '/Development',
        name: 'Development',
        meta: { isAuth: true, title: '链接管理' },
        component: () => import('@/views/back/Development.vue'),
      }, {
        path: '/Test',
        name: 'Test',
        meta: { isAuth: true, title: '文件管理' },
        component: () => import('@/views/back/Test.vue'),
      },
      {
        path: '/Page',
        name: 'Page',
        meta: { isAuth: true, title: '页面管理' },
        component: () => import('@/views/back/Page.vue'),
      },
      {
        path: '/DeliveryList',
        name: 'DeliveryList',
        meta: { isAuth: true, title: '清单说明' },
        component: () => import('@/views/back/DeliveryList.vue'),
      },
      {
        path: '/OpenManagement',
        name: 'OpenManagement',
        meta: { isAuth: true, title: '开放管理' },
        component: () => import('@/views/back/OpenManagement.vue'),
      },
      {
        path: '/MenuMana',
        name: 'MenuMana',
        meta: { isAuth: true, title: '菜单管理' },
        component: () => import('@/views/back/MenuMana.vue'),
      }, {
        path: '/RoleMana',
        name: 'RoleMana',
        meta: { isAuth: true, title: '角色管理' },
        component: () => import('@/views/back/RoleMana.vue'),
      }, {
        path: '/UserMana',
        name: 'UserMana',
        meta: { isAuth: true, title: '用户管理' },
        component: () => import('@/views/back/UserMana.vue'),
      }, {
        path: '/Dictionarie',
        name: 'Dictionarie',
        meta: { isAuth: true, title: '字典表' },
        component: () => import('@/views/back/Dictionarie.vue'),
      }, {
        path: '/System',
        name: 'System',
        meta: { isAuth: true, title: '系统参数' },
        component: () => import('@/views/back/System.vue'),
      }]
  },


  /**
  * 路由重定向
  */
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token') //读取token
  if (to.meta.isAuth) { //判断是否需要鉴权
    if (token === null) {
      // alert('未登录，无权限查看！')
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;