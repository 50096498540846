export default {
    namespaced:true,
    //用于响应组件中的动作，做逻辑处理，发送数据请求的异步方法
    actions: {
        asyncAddStoreCount(context: any, payload: any) { // 第一个参数是vuex固定的参数，不需要手动去传递
            // console.log(context);
            context.commit("addCont", payload)
        },
    },
    //用于操作数据的同步方法
    mutations: {
        addCont(state: any, num: any) {
            state.age += num
        }
    },
    //用于存储数据
    state: {
        age: 18,
        name: 'Justin Bieber'
    },
    //将数据进行加工计算属性方法
    getters: {
        getNum(state:any){
            return state.age * 2
        }
    },
    modules: {},
}