import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./request/http"  //封装axios
import ElementPlus from 'element-plus' //全局引用element Plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons';//导入所有element icon图标

const app = createApp(App)
app.use(store).use(router).use(http).use(ElementPlus, { size: 'default' , locale: zhCn, });

// 全局注册element-plus icon图标组件
Object.keys(ElIconModules).forEach((key) => {
    app.component(key, (ElIconModules as any)[key]);
});

app.mount("#app")