<template>
  <router-view />
</template>
<style lang="less">
html,body,#app {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: auto;
  cursor: default;
  background: #FFFFFF;
  font-size: 12px;
}

*{
  box-sizing: border-box;
}
</style>